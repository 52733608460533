import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import Content_StorageOptimized from "components/content/feature_storage-optimized.js";
import Content_StorageMigration from "components/content/feature_storage-migration.js";

const Page = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="saas-backup"
      title="Automated Gitea Backup Service"
      description="Automate your Gitea backups and all its meta data. Backup for your Gitea repo, accounts, issues, wiki and more."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">Gitea data</span> backup.</>)}
      subTitle={(
        <>Never worry about losing your vital data again.<br/>
          Effortlessly back up your Gitea repositories, user accounts, issues, wikis, and more.
        </>)}
      checkMarks={['Account data', 'Repositories', 'Issues', 'Wiki', 'Gists', 'Pull Requests']}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename={`brands/icons/gitea-ico.png`} alt={`Gitea backup`}  className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your Gitea Backup" urlAtrr={{ sb_source: "website", sb_term: `gitea-hero` }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-hero-dashboard.png" alt="SimpleBackups Gitea backup dashboard: all your Gitea backups in one place"/>
      )}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "trust your data is secure",
                title: (<>Never lose your <span className="text-primary">Gitea</span> data.</>),
                description: (
                  <>
                    <p>
                      Connect your Gitea account to set up automatic daily backups of your repositories and their associated metadata, including issues, pull requests, actions, wikis, and more.
                      <br/>
                      <Ui.FeatureChecks items={['Background automated backup', 'Gitea metadata backup']} />
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-saas-app-gitea.png'
                  alt="Gitea backup service"
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureControl({subject: 'Gitea'}),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "All your gitea data, secured",
                description: (
                  <>
                    <p>
                      Secure your Gitea data backup effortlessly – no coding, no upkeep, no installations required.<br/>
                      Your backups are automatically stored in your preferred cloud storage, eliminating the need for managing any Gitea APIs or scripts.
                    </p>
                  </>
                ),
                title: (<>No-code Gitea server backups</>),
                image: ( <Ui.Image
                className="shadow-2xl rounded-xl"
                filename='simplebackups-app-saas-app-gitea.png'
                alt="Gitea backup without code"
                style={{maxWidth: 450}}
            />)
              },
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Gitea backup?</>}
      text={`Find out more and get started with our Gitea  Backup guides`}
      tags={['saas-backup', 'saas-gitea']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_storage_replication_service', 'use_for_client_2', 'use_for_other_things']}/>

  </Layout.Layout>
)

export default Page
